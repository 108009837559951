export const AUTH_ROUTES = {
  SIGNIN: "/signin",
  RESET_PASS: "/reset-pass",
  CHANGE_PASS: "/validity-hash/:token",
};

export const MAIN_ROUTES = {
  DASHBOARD: "/dashboard",
  WEBSITE_HEADING: "/dealers/website-heading",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_CONDITIONS: "/terms-conditions",
  LOAN_LENDER: "/financial-services/exposed/get-loan-current-status/:id",
  INSURANCE_LENDER: "/financial-services/exposed/get-ins-current-status/:id",
  DEALER_SETTING: "/dealer/setting",
  USER_AND_PERMISSION: "/users-and-permissions",
  RTO_SERVICES: {
    ROUTE: "/Services",
    PICKUP_REQUEST: "DoorServices",
    ADD_DOOR_SERVICE: "add-door-service",
    EDIT_DOOR_SERVICE: "edit-door-service",
    VIEW_DOOR_SERVICE: "view-door-service",
    RTO_CASES: "RTOCases",
    ADD_RTO_CASE: "rto-case/new-request",
    EDIT_RTO_CASE: "rto-case/edit",
    VIEW_RTO_CASE: "rto-case/view",
    CHALLAN: "challan-list",
    NEW_CHALLAN_REQUEST: "challan/create-request",
    ADD_CHALLAN: "challan/new",
    VIEW_CHALLAN: "challan/view",
    EDIT_CHALLAN: "challan/edit",
    PHOTO_SHOOT: "PhotoShoot",
    ADD_PHOTO_SHOOT: "add-photoshoot",
    EDIT_PHOTO_SHOOT: "edit-photoshoot",
    VIEW_PHOTO_SHOOT: "view-photoshoot",
    SOCIAL_MEDIA: "SocialMedia",
    ADD_SOCIAL_MEDIA: "add-social-media",
    EDIT_SOCIAL_MEDIA: "edit-social-media",
    VIEW_SOCIAL_MEDIA: "view-social-media",
    WEBSITE_DEVELOPMENT: "WebsiteDevelopment",
    ADD_WEBSITE_DEVELOPMENT: "add-website-development",
    EDIT_WEBSITE_DEVELOPMENT: "edit-website-development",
    VIEW_WEBSITE_DEVELOPMENT: "view-website-development",
    DIGITAL_MARKETING: "DigitalMarketing",
    ADD_DIGITAL_MARKETING: "add-digital-marketing",
    EDIT_DIGITAL_MARKETING: "edit-digital-marketing",
    VIEW_DIGITAL_MARKETING: "view-digital-marketing",
    CREATIVE_MAKING: "CreativeMaking",
    ADD_CREATIVE_MAKING: "add-creative",
    EDIT_CREATIVE_MAKING: "edit-creative",
    VIEW_CREATIVE_MAKING: "view-creative",
    VIEW_VAHAN_CHECK: "view-vahan-check",
    VAHAN_CHECK: "VahanCheck",
    ADD_VAHAN_CHECK: "add-vahan-check",
    D2D_AUCTION: "d2d-auction",
    ADD_D2D_AUCTION: "add-d2d-auction",
    EDIT_D2D_AUCTION: "edit-d2d-auction",
    VIEW_D2D_AUCTION: "view-d2d-auction",
    D2D_REQUIREMENT: "d2d-requirement",
    ADD_D2D_REQUIREMENT: "add-d2d-requirement",
    EDIT_D2D_REQUIREMENT: "edit-d2d-requirement",
    VIEW_D2D_REQUIREMENT: "view-d2d-requirement",
  },

  DEALER_MANAGEMENT: {
    ROUTE: "/dealer-management",
    DEALERS: "dealers",
    NEW_DEALER: "dealer/new-request",
    EDIT_DEALER: "dealer/edit",
    VIEW_DEALER: "dealer/view",
    DEALER_PREFERENCE: "dealer/preference",
    DEALER_SETTING: "dealer/setting",
    UPLOAD_DEALER: "dealer/Upload-list",
    DOCUMENT_UPLOAD: "dealer/upload-documents",
    WEBSITE_HEADING: "dealer/website-heading",
    DEALERSUBUSER: "dealersubuser",
    NEW_DEALERSUBUSER: "dealersubuser/new-request",
    VIEW_DEALERSUBUSER: "dealersubuser/view",
    EDIT_DEALERSUBUSER: "dealersubuser/edit",
    DEALER_SETTING: "dealer/setting",
  },

  MASTER_MANAGEMENT: {
    ROUTE: "/master-management",
    USERS: "users",
    NEW_USER: "user/new-request",
    VIEW_USER: "user/view",
    ROLES: "roles",
    NEW_ROLE: "role/new-request",
    VIEW_ROLE: "role/view",
    EDIT_ROLE: "role/edit",
  },
  SERVICE_HISTORY: {
    ROUTE: "/service-history",
    SERVICE_LIST: "service-list",
    NEW_SERVICE: "service/new-request",
    VIEW_SERVICE: "service/view",
    EDIT_SERVICE: "service/edit",
  },
  TRANSACTIONS: {
    ROUTE: "/transaction",
    TRANSACTION_LIST: "transaction-list",
    BANK_TRANSACTION: "bank",
    ADD_BANK_TRANSACTION: "bank/new",
    OUTSTANDING_TRANSACTION: "outstanding",
    SERVICE_TRANSACTION: "service",
  },
  INVENTORY: {
    ROUTE: "/inventory",
    INVENTORY_LIST: "inventory-list",
    NEW_INVENTORY: "inventory-request",
    EDIT_DETAILS: "edit-inventory",
    UPLOAD_IMAGES: "upload-images",
  },
  FINANCIALSERVICES: {
    ROUTE: "/financial-services",
    LOANS_LIST: "loans-list",
    ADD_LOAN: "loans-list/new",
    EDIT_LOAN: "loans-list/edit",
    VIEW_LOAN: "loans-list/view",
    INSURANCE_LIST: "insurance-list",
    ADD_INSURANCE: "insurance-list/new",
    EDIT_INSURANCE: "insurance-list/edit",
    VIEW_INSURANCE: "insurance-list/view",
  },
  LEAD: {
    ROUTE: "/lead",
    LEAD_LIST: "lead-list",
    NEW_LEAD: "lead-request",
    CUSTOMER_LEAD: "customer-lead",
    CUSTOMER_PREFERENCES: "customer-preferences",
    ASSIGN_DEALERS: "assign-dealers",
  },
};

export const getAbsolute = (route) => {
  let baseRoute = getParentRouteObject(AUTH_ROUTES, route);
  if (baseRoute != null) {
    return baseRoute.ROUTE + "/" + route;
  }
  baseRoute = getParentRouteObject(MAIN_ROUTES, route);
  if (baseRoute != null) {
    return baseRoute.ROUTE + "/" + route;
  }
};

const getParentRouteObject = (object, route) => {
  if (typeof object !== "object" || object === null) {
    return null;
  }
  for (const prop in object) {
    if (Object.hasOwnProperty.call(object, prop)) {
      if (object[prop] === route) {
        return object;
      }
      const parent = getParentRouteObject(object[prop], route);
      if (parent) {
        return parent;
      }
    }
  }
  return null;
};
